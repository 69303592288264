.productContact {
}

.productContact__inner {
    @include pageWidth;
    max-width: 1110px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.productContact__details {
    flex-grow: 1;
    color: $_colorTundora;
}

.productContact__contact + .productContact__contact {
    margin-top: 30px;
}

.productContact__headline {
    margin-bottom: 20px;

    font-weight: 600;
    font-size: 24px;
    text-align: center;
}

.productContact__subheadline {
    margin-bottom: 5px;
    font-weight: 600;
}

.productContact__address {
    margin-bottom: 1em;
}

.productContact__channels {
    td {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.productContact__link {
    font-weight: 600;
    color: $_colorShiraz;
}

.productContact__form {
    margin-top: 30px;
}

@media (min-width: 480px) {
    .productContact__inner {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .productContact__headline {
        margin-bottom: 20px;

        font-size: 30px;
        text-align: left;
    }
}

@media (min-width: 768px) {
    .productContact__inner {
        flex-direction: row;
    }

    .productContact__details {
        padding-right: 30px;
    }

    .productContact__form {
        margin-top: 0;

        &.form{
            width: 66%;
        }
    }
}
