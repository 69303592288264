.checkboxList {
    padding: 5px;
    width: 100%;
}

.checkboxList__label {
    color: $_colorMidGray;
    margin-bottom: 10px;
}

.checkboxList__labelRequried {
    color: $_colorShiraz;
}

.checkboxList__list {
    margin: 0 -5px;
}
