$baseClass: 'dashboardIntro';

.#{$baseClass} {
  @include pageWidth;

  padding-bottom: 25px;
  color: $_colorTundora;
}

.#{$baseClass}__login {
  margin-top: 15px;

  font-size: 14px;
  text-align: right;
}

.#{$baseClass}__headline {
  margin-top: 10px;
  margin-bottom: 15px;

  font-size: 30px;
  font-weight: 700;
}

.#{$baseClass}__text {
  max-width: 990px;

  @include wysiwyg;
}

@media (min-width: 480px) {
  .#{$baseClass} {
    padding-bottom: 50px;
  }

  .#{$baseClass}__headline {
    font-size: 40px;
  }
}
