$baseClass: 'magazineContent';

.#{$baseClass}__inner {
    @include pageWidth;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.#{$baseClass}__item {
    & + & {
        margin-top: 30px;
    }
}

.#{$baseClass}__title {
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 1.3;
    color: $_colorMineShaft;
}

.#{$baseClass}__wysiwyg {
    font-size: 18px;
    color: $_colorTundora;
    letter-spacing: 1.03px;
    @include wysiwyg;
}

.#{$baseClass}__image,
.#{$baseClass}__videoVideo {
    margin-top: 30px;
}

.#{$baseClass}__imageImg {
    max-width: 100%;
    margin: 0 auto;

    &--zoom {
        cursor: zoom-in;
    }
}

.#{$baseClass}__imageText {
    padding: 10px 0 0;
}

.#{$baseClass}__imageCaption {
    font-size: 16px;
    text-align: center;
    color: $_colorTundora;

    & > * {
        margin: 0;
    }

    a {
        color: $_colorShiraz;
        font-weight: 600;
    }
}

.#{$baseClass}__imageHint {
    font-size: 12px;
    text-align: center;
    color: $_colorTundora;
}

.#{$baseClass}__videoVideo {
    width: 100%;
    padding-bottom: 56.35%;
    position: relative;

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.#{$baseClass}__contact {
    margin-top: 30px;
}

.#{$baseClass}__sidebox {
    margin-top: 30px;
}

@media (min-width: 480px) {
    .#{$baseClass} {
        padding: 0 25px;
    }

    .#{$baseClass}__inner {
        padding-bottom: 70px;
    }

    .#{$baseClass}__title {
        font-size: 36px;
    }

    .#{$baseClass}__item {
        & + & {
            margin-top: 50px;
        }
    }

    .#{$baseClass}__image,
    .#{$baseClass}__videoVideo {
        margin-top: 50px;
    }

    .#{$baseClass}__contact {
        margin-top: 50px;
    }

    .#{$baseClass}__sidebox {
        margin-top: 50px;
    }
}

@media (min-width: 950px) {
    .#{$baseClass}__inner {
        flex-direction: row;
    }

    .#{$baseClass}__content {
        flex-grow: 999;
        flex-basis: 100%;
        padding-right: 50px;
    }

    .#{$baseClass}__sidebox {
        margin-top: 0;
        flex-grow: 1;
        flex-shrink: 0;
        width: 400px;
        flex-shrink: 0;
    }
}

@media print {
    .#{$baseClass},
    .#{$baseClass}__inner {
        display: block;
        padding-left: 0;
        padding-right: 0;
    }

    .#{$baseClass}__itemHeader {
        break-inside: avoid;
    }

    .#{$baseClass}__title {
        font-size: 24px;
    }

    .#{$baseClass}__wysiwyg {
        font-size: 14px;
    }

    .#{$baseClass}__video {
        display: none;
    }

    .#{$baseClass}__image {
        position: relative;
        max-width: 33%;
        margin-left: auto;
        margin-right: auto;
        break-inside: avoid;
    }

    .#{$baseClass}__imageCaption {
        font-size: 12px;
    }

    .#{$baseClass}__imageHint {
        display: none;
    }
}
