.heroLink {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 16px;
  color: $_colorWhite;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  font-weight: 600;
}

.heroLink--dark {
  color: $_colorTundora;
  text-shadow: 0 0 1px $_colorWhite;

  .heroLink__icon {
    box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.5);

    path {
      fill: $_colorTundora;
    }
  }
}

.heroLink--shiraz {
  color: $_colorShiraz;
  text-shadow: 0 0 1px $_colorWhite;

  .heroLink__icon {
    box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.5);

    path {
      fill: $_colorShiraz;
    }
  }
}

.heroLink__icon {
  position: relative;
  margin-top: 5px;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 30px;
    height: 30px;
  }

  path {
    fill: $_colorWhite;
  }
}

@media (min-width: 480px) {
  .heroLink {
    font-size: 20px;
  }

  .heroLink__icon {
    width: 36px;
    height: 36px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
}
