.productHeader {
    background: $_colorAlabaster;
}

.productHeader__inner {
    @include pageWidth;
    max-width: 1110px;
    padding-bottom: 15px;
}

.productHeader__gallery {
    flex-grow: 4;
    min-width: 250px;
    user-select: none;
}

.productHeader__image {
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.18);
}

.productHeader__images {
    width: 300%;
    overflow: hidden;
    transition: transform $_tShort ease-in-out;

    img {
        display: block;
        width: 33.333333%;
        float: left;
    }
}

.productHeader__controls {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:hover {
        .productHeader__previous,
        .productHeader__next {
            opacity: 0.6;
        }
    }
}

.productHeader__previous,
.productHeader__next {
    position: absolute;
    top: 50%;
    padding: 20px 7px;
    transform: translate(0, -50%);
    background: $_colorTundora;

    opacity: 0.3;

    cursor: pointer;
    transition: opacity $_tShort ease-in-out;

    &:hover {
        opacity: 0.9 !important;
    }

    svg {
        display: block;
        height: 22px;
        width: 14px;
    }

    path {
        fill: $_colorWhite;
    }
}

.productHeader__previous {
    left: 0;
}

.productHeader__next {
    right: 0;
}

.productHeader__thumbnails {
    margin: 5px -5px;
}

.productHeader__thumbnailsInner {
    display: flex;
}

.productHeader__thumbnail {
    padding: 5px;
    flex-grow: 1;
    cursor: pointer;
    position: relative;

    img {
        display: block;
        width: 100%;
        box-shadow: 0 0 3px 0 rgba(0,0,0,0.18);
    }

    &::after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        background: rgba(0, 0, 0, 0.3);
    }

    &--invisible {
        visibility: hidden;
        pointer-events: none;
    }
}

.productHeader__thumbnail--active {
    &::after {
        content: none;
    }
}

.productHeader__content {
    flex-grow: 6;
}

.productHeader__adhoc {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: $_colorShiraz;

    svg {
        display: block;
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }

    path {
        fill: $_colorShiraz;
    }
}

.productHeader__headline {
    font-weight: 600;
    font-size: 24px;
    color: $_colorTundora;
}

.productHeader__suheadline {
    margin-top: 5px;
    font-weight: 600;
    color: $_colorTundora;
}

.productHeader__nuclides {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
}

.productHeader__nuclide {
    padding: 2px 10px;
    border-radius: 4px;
    background: $_colorShiraz;
    white-space: nowrap;

    font-size: 14px;
    color: $_colorGallery;

    margin-right: 5px;
    margin-bottom: 5px;
}

.productHeader__intro {
    @include wysiwyg;

    color: $_colorTundora;
}

.productHeader__warning {
    margin-top: 15px;
    padding: 10px 15px;

    @include wysiwyg;

    color: $_colorTundora;
    border: 2px solid $_colorShiraz;
    background: rgba(255, 255, 255, 0.5);
    font-size: 14px;
}

.productHeader__downloads {
    margin-top: 25px;
}

.productHeader__download {
    flex-shrink: 0;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    svg {
        flex-shrink: 0;
        width: 30px;
        height: 22px;
        margin-right: 10px;
        fill: $_colorTundora;
    }
}

.productHeader__downloadText {
    font-size: 16px;
    line-height: 1.15;
    color: $_colorTundora;
    white-space: nowrap;
    border-bottom: 1px solid $_colorTundora;
    text-overflow: ellipsis;
    overflow: hidden;
}


@media (min-width: 550px) {
    .productHeader__inner {
        padding-bottom: 30px;
        display: flex;
    }

    .productHeader__gallery {
        padding-right: 30px;
        width: 40%;
    }

    .productHeader__content {
        width: 60%;
    }

    .productHeader__headline {
        margin-bottom: 5px;
        font-size: 30px;
        line-height: 1.1;
    }

    .productHeader__suheadline {
        margin-top: 0;
        font-size: 20px;
    }

    .productHeader__downloads {
        margin-top: 30px;
    }
}

@media (min-width: $_wLarge) {
    .productHeader__inner {
        padding-bottom: 45px;
    }

    .productHeader__gallery {
        padding-right: 60px;
    }

    .productHeader__headline {
        font-size: 40px;
    }

    .productHeader__suheadline {
        font-size: 24px;
    }

    .productHeader__downloads {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .productHeader__download {
        min-width: 50%;
        padding-right: 10px;
    }
}
