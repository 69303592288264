.dropdown {
  position: relative;
  margin: 5px;
  width: calc(100% - 10px);

  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    pointer-events: none;
  }

  path {
    fill: $_colorTundora;
  }
}

.dropdown__select {
  display: block;
  width: 100%;
  height: 41px;
  appearance: none;
  line-height: 41px;
  padding: 0 40px 0 15px;
  outline: none;
  border: 1px solid $_colorSilver;
  box-shadow: inset 0 0 0 21px $_colorAlabaster;
  border-radius: 2px;
  color: $_colorTundora;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    border-color: $_colorTundora;
  }
}
