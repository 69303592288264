$baseClass: "form";

.#{$baseClass} {
  position: relative;
  width: 100%;
  padding: 20px;
  background: $_colorGallery;
  color: $_colorTundora;
}

.#{$baseClass}__headline {
  font-size: 24px;
  font-weight: 700;
}

.#{$baseClass}__subtitle {
  margin-top: 7px;
  font-weight: 600;
  font-size: 18px;
}

.#{$baseClass}__text {
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.5;
}

.#{$baseClass}__form {
  display: flex;
  flex-wrap: wrap;
}

.#{$baseClass}__row {
  padding: 5px 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  width: calc(100% + 10px);
}

.#{$baseClass}__rowHalf {
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 50%;
  max-width: 100%;
}

@media (min-width: 480px) {
  .#{$baseClass}__rowHalf {
    flex-basis: 50%;
  }
}

.#{$baseClass}__recaptcha {
  width: 100%;
  padding: 6px;
  display: flex;
  justify-content: center;

  & > div {
    overflow: hidden;
    opacity: 0;
  }

  &--normal > div {
    overflow: hidden;
    width: 302px !important;
    height: 76px !important;
  }

  &--compact > div {
    width: 158px !important;
    height: 138px !important;
  }

  &--normal,
  &--compact {
    & > div {
      opacity: 1;
    }
  }
}

.#{$baseClass}__buttonRow {
  padding: 6px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0 -5px;
  width: calc(100% + 10px);
}

@media (min-width: 480px) {
  .#{$baseClass} _ {
    padding: 25px 25px 20px;
  }
}

@media print {
  .#{$baseClass} {
    display: none !important;
  }
}
