$baseClass: 'magazineHero';

.#{$baseClass}__inner {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: calc(50vw - 45px);
    position: relative;
    background: $_colorAlabaster;

    &:hover {
        .#{$baseClass}__img {
            filter: saturate(1);
        }
    }
}

.#{$baseClass}__tile {
    position: relative;
    z-index: 1;
    top: 0;
    margin: 30px 16px;
}

.#{$baseClass}__img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;

    filter: saturate(0);
    transition: filter 0.3s ease-in-out;
}

@media print, (min-width: 480px) {
    .#{$baseClass}__tile {
        left: 50px;
        margin: 50px 0;
        width: 380px;
    }
}

@media print, (min-width: 950px) {
    .#{$baseClass} {
        padding: 50px 25px 0;
    }

    .#{$baseClass}__outer {
        @include pageWidth;
    }

    .#{$baseClass}__tile {
        left: -30px;
    }
}

@media (min-width: 1300px) {
    .#{$baseClass}__inner {
        min-height: 0;
        height: calc(50vw - 45px);
        max-height: 650px;
    }
}

@media print {
    .#{$baseClass} {
        padding: 0;
    }

    .#{$baseClass}__outer {
        padding: 0;
    }

    .#{$baseClass}__inner {
        min-height: 0;
    }

    .#{$baseClass}__tile {
        margin: 20px 0;
        left: 20px;
    }
}
