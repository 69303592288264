$baseClass: 'magazineArticleGrid';

.#{$baseClass} {
    break-inside: avoid;
    padding: 0 20px;

    &--highlight {
        background: $_colorShipGray;
    }

    &--main {
        .#{$baseClass}__tiles {
            padding-bottom: 50px;
        }
    }
}

.#{$baseClass}__inner {
    @include pageWidth;
}

.#{$baseClass}__title {
    padding-top: 50px;
    color: $_colorWhite;
    font-size: 30px;
    font-weight: bold;
}

.#{$baseClass}__tiles {
    display: flex;
    flex-wrap: wrap;
    padding: 35px 0;
    margin: 0 -25px;

    & > * {
        width: 100%;
    }
}

@media print, (min-width: 480px) {
    .#{$baseClass} {
        padding: 0 25px;
    }

    .#{$baseClass}__title {
        padding-top: 60px;
        font-size: 48px;
    }
}

@media print, (min-width: 650px) {
    .#{$baseClass}__tiles {
        & > * {
            width: 50%;
        }
    }
}

@media print, (min-width: 950px) {
    .#{$baseClass}__tiles {
        & > * {
            width: 33.333%;
        }
    }
}

@media print, print {
    .#{$baseClass}__tiles {
        & > * {
            width: 33.333%;
        }
    }
}
