.productForbidden {
    background: $_colorGallery;
}

.productForbidden__inner {
    @include pageWidth;
    padding: 40px 20px;
}

.productForbidden__headline {
    margin-bottom: 15px;

    font-weight: 600;
    font-size: 24px;
    color: $_colorTundora;
}

.productForbidden__text {
    @include wysiwyg;
    color: $_colorTundora;
    max-width: 800px;
}

@media (min-width: 480px) {
    .productForbidden__inner {
        padding: 80px 25px 80px;
    }

    .productForbidden__headline {
        font-size: 30px;
    }
}
