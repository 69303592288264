.doccheck {

}

.doccheck__inner {
    @include pageWidth;
    padding: 40px 20px;
}

.doccheck__headline {
    margin-bottom: 15px;

    font-weight: 600;
    font-size: 24px;
    color: $_colorTundora;
}

.doccheck__text {
    color: $_colorTundora;
    max-width: 800px;
}

.doccheck__login {
    margin-top: 20px;
    border: none;
    overflow: hidden;
}

.doccheck__login--mobile {
    display: block;
    width: 165px;
    height: 210px;
}

.doccheck__login--desktop {
    display: none;
}


.doccheck__link {
    font-weight: 600;
    color: $_colorShiraz;
    cursor: pointer;
}

@media (min-width: 400px) {
    .doccheck__login--desktop {
        display: block;
        width: 360px;
        height: 210px;
    }

    .doccheck__login--mobile {
        display: none;
    }
}

@media (min-width: 480px) {
    .doccheck__inner {
        padding: 40px;
    }

    .doccheck__headline {
        font-size: 30px;
    }
}
