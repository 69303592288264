$baseClass: 'productCategories';

.#{$baseClass} {
  overflow: hidden;
  padding: 20px 20px 30px;
  background: $_colorGallery;
  // box-shadow: inset 1px 5px 3px -2px rgba(0,0,0,0.05), inset 1px -5px 3px -2px rgba(0,0,0,0.05);

  &--white {
    background: $_colorWhite;
  }
}

.#{$baseClass}__inner {
  margin: 0 auto;
  max-width: 100%;
  width: 100vw;
}

.#{$baseClass}__headline {
  margin-bottom: 25px;

  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: $_colorTundora;
}

.#{$baseClass}__items {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.#{$baseClass}__item {
  width: 100%;
  padding: 5px;
  flex-grow: 1;
}

.#{$baseClass}__itemInner {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  padding: 20px;
  background-color: $_colorNobel;
  background-position: center;
  background-size: cover;
  position: relative;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: rgba(0, 0, 0, 0.7);
  }
}

.#{$baseClass}__itemHeadline,
.#{$baseClass}__buttonInner {
  position: relative;
  z-index: 1;
  text-align: center;
  font-weight: 600;
  color: $_colorWhite;
  font-size: 20px;
}

.#{$baseClass}__itemHeadline {
  transition: opacity $_tShort ease-in-out;
}

.#{$baseClass}__overlay {
  display: none;
}

.#{$baseClass}__button {
  width: 100%;
  padding: 5px;
}

.#{$baseClass}__buttonInner {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;

  background: $_colorShiraz;
  color: $_colorWhite;
  transition: color $_tShort ease-in-out, background $_tShort ease-in-out;

  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.7);

  &:hover {
    background: lighten($_colorShiraz, 10);
  }
}

@media (min-width: 480px) {
  .#{$baseClass} {
    padding: 40px 20px 50px;
  }

  .#{$baseClass}__headline {
    font-size: 40px;
  }

  .#{$baseClass}__item {
    width: 50%;
  }

  .#{$baseClass}__itemHeadline,
  .#{$baseClass}__buttonInner {
    font-size: 30px;
  }

  .#{$baseClass}__itemInner {
    height: 350px;
    padding: 20px 30px;

    &:hover {
      .#{$baseClass}__overlay {
        opacity: 1;
      }

      .#{$baseClass}__overlayContent {
        transform: translate(0, 0);
      }

      .#{$baseClass}__itemHeadline {
        opacity: 0;
      }
    }
  }

  .#{$baseClass}__overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 40px 25px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparentize($_colorTundora, 0.2);

    color: $_colorWhite;
    text-align: center;

    transition: opacity $_tShort ease-in-out;
  }

  .#{$baseClass}__overlayContent {
    width: 100%;
    transform: translate(0, -20px);
    transition: transform $_tShort ease-in-out;
  }

  .#{$baseClass}__overlayHeadline {
    font-weight: 600;
    font-size: 20px;
  }

  .#{$baseClass}__overlayText {
    margin-top: 20px;

    font-size: 16px;
  }

  .#{$baseClass}__overlayButton {
    margin-top: 35px;
  }
}

@media (min-width: 1000px) {
  .#{$baseClass}__item {
    width: 25%;
  }

  .#{$baseClass}__itemInner {
    height: 450px;
  }
}

.overlay__content {
  .#{$baseClass} {
    padding: 0;
    background: transparent;
  }

  .#{$baseClass}__inner {
    padding: 5px;
    background: white;
  }
}

@media (min-width: 1340px) {
  .#{$baseClass}__inner {
    max-width: 1300px;
  }
}
