.footerNavigation {
  background: $_colorGallery;
}

.footerNavigation__center {
  @include pageWidth;
}

.footerNavigation__inner {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.footerNavigation__menu {
  padding: 10px 0;
  width: 100%;
  order: 1;
}

.footerNavigation__menu--newsletter {
  order: 0;
  padding-bottom: 30px;
}

.footerNavigation__menuHeadline {
  font-weight: 700;
  font-size: 20px;
  color: $_colorTundora;
}

.footerNavigation__menuEntries,
.footerNavigation__form {
  margin-top: 5px !important;
}

.footerNavigation__menuEntries {
  @include clearfix;
}

.footerNavigation__menuEntry {
  float: left;
  clear: left;
  color: $_colorTundora;
  line-height: 20px;
  padding: 6px 0;
}

.footerNavigation__form {
  width: calc(100% + 10px);
  margin: 0 -5px;
}

@media (min-width: 480px) {
  .footerNavigation__menu {
    width: 50%;
    padding: 20px 20px 20px 0;

    &:last-child {
      padding-right: 0;
    }
  }

  .footerNavigation__menu--newsletter {
    order: 1;
  }

  .footerNavigation__menuHeadline {
    font-size: 24px;
  }

  .footerNavigation__menuEntries,
  .footerNavigation__form {
    margin-top: 10px !important;
  }
}

@media (min-width: 769px) {
  .footerNavigation__inner {
    padding: 0;
  }

  .footerNavigation__menu {
    width: 25%;
    padding: 25px 25px 25px 0;
  }
}
