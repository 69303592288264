$baseClass: 'formHero';

.#{$baseClass} {
  position: relative;
  background-color: $_colorAlto;
  background-position: center right;
  background-size: cover;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 1);

  &::after,
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::before {
    z-index: 0;
    background-image: linear-gradient(
      135deg,
      $_colorWhite 0%,
      $_colorWhite 40%,
      transparentize($_colorWhite, 1) 100%
    );
  }

  &::after {
    z-index: 1;
    background: transparentize($_colorChathamsBlueDark, 0.35);
  }

  &--button {
    .#{$baseClass}__content {
      padding-bottom: 80px;
    }
  }
}

.#{$baseClass}__inner {
  position: relative;
  z-index: 2;

  @include pageWidth;

  min-height: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.#{$baseClass}__content {
  width: 100%;
  text-shadow: 0 0 3px #0a3140;
  color: $_colorWhite;
  margin-bottom: 15px;
}

.#{$baseClass}__categories {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  path {
    fill: $_colorWhite;
  }
}

.#{$baseClass}__categoriesText {
  font-size: 12px;
  color: $_colorWhite;
}

.#{$baseClass}__subtitle {
  font-size: 20px;
  font-weight: 600;
}

.#{$baseClass}__headline {
  margin-top: 5px;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.1;
}

.#{$baseClass}__text {
  max-width: 700px;
  margin-top: 15px;

  font-size: 16px;
}

.#{$baseClass}__link {
  display: none !important;
}

@media (min-width: 650px) {
  .#{$baseClass}__inner {
    display: flex;
    align-items: center;
    min-height: 580px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .#{$baseClass}__categories {
    justify-content: flex-start;
  }

  .#{$baseClass}__subtitle {
    font-size: 30px;
  }

  .#{$baseClass}__headline {
    font-size: 40px;
  }

  .#{$baseClass}__text {
    margin-top: 25px;

    font-size: 24px;
  }

  .#{$baseClass}__form {
    margin-left: 25px;
    width: 340px;
    flex-shrink: 0;
  }

  .#{$baseClass}__link {
    display: flex !important;
  }
}
