.eventsNewsletter {
    margin: 30px 0 10px;
}

.eventsNewsletter__inner {
    @include pageWidth;

    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
}


@media (min-width: 768px) {
    .eventsNewsletter {
        margin: 50px 0 30px;
    }

    .eventsNewsletter__inner {
        flex-direction: row;
    }

    .newsletter.form {
        width: 50%;
    }
}
