$baseClass: "Button";

.#{$baseClass} {
  padding: 0 10px;
  border: none;
  height: 41px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 16px;

  border-radius: 3px;
  cursor: pointer;
  appearance: none;
  outline: none;

  transition: color $_tXShort ease-in-out, background $_tXShort ease-in-out;

  &--cta {
    background: $_colorShiraz;
    color: $_colorWhite !important;

    &:hover,
    &:focus {
      background: lighten($_colorShiraz, 10);
    }
  }

  &--green {
    background: $_colorChathamsBlueDark;
    color: $_colorWhite !important;

    &:hover {
      background: lighten($_colorChathamsBlueDark, 10);
    }
  }

  &--6,
  &--12 {
    width: 100%;
  }

  &--margin {
    margin: 5px;
    max-width: calc(100% - 10px);
  }

  &--small {
    flex-grow: 0;
  }

  &--ghost {
    border: 1px solid $_colorSilver;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.3);

    &:hover,
    &:focus {
      background: $_colorAlto;
    }

    &.#{$baseClass}--white {
      border-color: $_colorWhite;

      &:hover,
      &:focus {
        background: $_colorWhite;
        color: $_colorTundora;
      }
    }

    .#{$baseClass}__inner {
      height: 37px;
    }
  }

  &--inline {
    margin-bottom: 10px;
  }

  &--disabled {
    background: $_colorDustyGray !important;
    pointer-events: none;
  }
}

.#{$baseClass}__inner {
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
}

@media (min-width: 480px) {
  .#{$baseClass} {
    &--inline {
      display: inline-block;
      padding: 0 15px;
    }

    &--margin {
      margin: 5px;
      max-width: calc(100% - 10px);

      &.#{$baseClass}--6 {
        width: calc(50% - 10px);
      }
    }
  }
}
