.countryPrivacySelection--modal {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);

  .countryPrivacySelection__inner {
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }

  .countryPrivacySelection__center {
    margin-top: 50px;
    padding: 20px;
    max-height: 100vh;
    overflow: auto;
  }
}

.countryPrivacySelection--static {
  margin-bottom: 1em;
}

.countryPrivacySelection__inner {
  max-width: 480px;
}

.countryPrivacySelection__center {
  width: 100%;
  background: $_colorWhite;
}

.countryPrivacySelection__logo {
  display: block;
  width: 100%;
  max-width: 250px;
  margin: 0 auto 20px;
}

.countryPrivacySelection__headline {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.countryPrivacySelection__text {
  font-size: 14px;
  margin-bottom: 16px;
  color: $_colorTundora;

  a {
    text-decoration: underline;
  }
}

.countryPrivacySelection__inputs {
  margin-top: 20px;
  margin: -5px;
  display: flex;
  flex-wrap: wrap;
}

.countryPrivacySelection__input {
  margin: 16px -5px -5px;
}

.countryPrivacySelection__form {
  color: $_colorTundora;
}

.countryPrivacySelection__section {
  margin-top: 16px;
  padding: 20px;
  background: $_colorGallery;
}

@media (max-width: 479px) {
  .countryPrivacySelection {
    .form {
      padding: 10px;
    }
  }
}
