.events {
  width: 100%;
  color: $_colorTundora;
}

.events__listing {
  margin-top: 30px;
}

.events__headline {
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
}

.events__item {
  padding: 20px 0 23px;

  & + & {
    border-top: 1px solid $_colorAlto;
  }
}

.events__itemDate {
  margin-bottom: 4px;
  font-size: 14px;
}

.events__itemHeadline {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
}

.events__itemLocation {
  font-size: 14px;
}

.events__itemDescription {
  margin-top: 10px;

  font-size: 16px;
  line-height: 1.5;
}

.events__itemMore {
  margin: 15px 0 0;
}

.events__empty {
  margin-top: 15px;
}

@media (min-width: 480px) {
  .events {
    margin-top: 0;
    padding-right: 20px;
  }

  .events__headline {
    font-size: 35px;
  }
}

@media (min-width: 768px) {
  .events {
    width: 50%;
    padding-right: 40px;
  }

  .events__listing {
    &:first-child {
      margin-top: 0;
    }
  }
}
