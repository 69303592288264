.categoryHero {
  position: relative;
  background-color: $_colorAlto;
  background-position: center right;
  background-size: cover;

  color: $_colorWhite;

  &::after,
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::before {
    z-index: 0;
    background-image: linear-gradient(
      135deg,
      $_colorWhite 0%,
      $_colorWhite 40%,
      transparentize($_colorWhite, 1) 100%
    );
  }

  &::after {
    z-index: 1;
    background: transparentize($_colorChathamsBlueDark, 0.35);
  }

  &--dark {
    &::after {
      background: transparentize($_colorBlack, 0.3);
    }
  }

  &--light {
    &::after {
      background: transparentize($_colorWhite, 0.35);
    }

    .categoryHero__inner {
      text-shadow: 0 0 2px $_colorWhite;
    }
  }

  &--button {
    .categoryHero__content {
      padding-bottom: 80px;
    }
  }
}

.categoryHero__inner {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  @include pageWidth;
  min-height: 300px;
  padding-top: 24px;
  padding-bottom: 24px;

  text-shadow: 0 0 3px #0a3140;
}

.categoryHero__content {
  max-width: 700px;
}

.categoryHero__categories {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  path {
    fill: $_colorWhite;
  }
}

.categoryHero__categoriesText {
  font-size: 12px;
  color: $_colorWhite;
}

.categoryHero__headline {
  margin-top: 10px;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.1;
}

.categoryHero__text {
  margin-top: 15px;

  font-weight: 600;
  font-size: 16px;
}

.categoryHero__additional {
  display: none;

  margin-top: 1em;
  font-size: 14px;

  p {
    line-height: 1.5;
  }
}

.categoryHero__toggle {
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

@media (min-width: 480px) {
  .categoryHero__inner {
    min-height: 400px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .categoryHero__categories {
    justify-content: flex-start;
  }

  .categoryHero__headline {
    font-size: 40px;
  }

  .categoryHero__text {
    margin-top: 25px;

    font-size: 20px;
  }

  .categoryHero__additional {
    display: block;

    font-size: 14px;
  }

  .categoryHero__toggle {
    display: none;
  }
}
