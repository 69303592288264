$baseClass: 'input';

.#{$baseClass} {
    padding: 5px;
    flex-grow: 1;

    &--6,
    &--12 {
        width: 100%;
    }


    &--hidden {
        position: absolute;
        left: -9999px;
        opacity: 0;
    }

    &--disabled {
        .#{$baseClass}__inner {
            opacity: 0.5;
        }
    }
}

.#{$baseClass}__inner {
    overflow: hidden;
    position: relative;
}

.#{$baseClass}__field {
    width: 100%;
    padding: 12px 15px 0;
    height: 41px;
    background: $_colorAlabaster;
    box-shadow: inset 0 0 0 21px $_colorAlabaster;
    border: 1px solid $_colorSilver;
    color: $_colorTundora;
    border-radius: 2px;
    font-size: 14px;

    overflow: hidden;
    text-overflow: ellipsis;

    display: block;
    transition: border-color 0.25s ease-in-out;

    &:focus {
        border-color: $_colorTundora;
    }
}

.#{$baseClass}__label {
    width: calc(100% - 20px);
    display: block;
    margin: 0;
    color: $_colorMidGray;
    transform: translate3d(15px, 12px, 0);
    position: absolute;
    z-index: 4;
    transition: all $_tShort ease-in-out;
    pointer-events: none;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.#{$baseClass}__labelRequried {
    color: $_colorShiraz;
}

.no-js,
.#{$baseClass}--hasContent,
.#{$baseClass}--focussed {
    .#{$baseClass}__label {
        font-size: 10px;
        transform: translate3d(15px, 5px, 0);
        transition: all 0.3s ease-in-out;
    }
}

// -----------------------------------------------------------------------------
//  STATE DEFINITIONS
// -----------------------------------------------------------------------------

.#{$baseClass}--focussed .#{$baseClass}__text {
    border-color: $_colorBlack;
}


@media (min-width: 480px) {
    .#{$baseClass}--6 {
        width: 50%;
    }
}
