$baseClass: 'headerBottom';

.#{$baseClass}__search,
.#{$baseClass}__logout {
  display: none;
}

.#{$baseClass} {
  &--red {
    .#{$baseClass}__navigationEntry {
      &--active {
        background: $_colorShiraz;
      }
    }
  }

  &--grey {
    .#{$baseClass}__navigationEntry {
      &--active {
        background: $_colorGravel;
      }
    }
  }
}

.#{$baseClass}__navigationEntry {
  &--active {
    background: $_colorChathamsBlue;
  }
}

@media (max-width: 479px) {
  .#{$baseClass} {
    position: fixed;
    z-index: 1;
    left: -100%;
    top: 50px;
    width: 200%;
    height: calc(100vh);
    background: rgba(0, 0, 0, 0);

    transform: translate3d(0, 0, 0);
    transition: transform $_tShort ease-in-out, background $_tShort ease-in-out;
    pointer-events: none;

    &--menu {
      transform: translate3d(50%, 0, 0);
      background: $_colorLayer;
      pointer-events: all;
    }

    &--red {
      .#{$baseClass}__inner {
        background: $_colorShirazDark;
      }

      .#{$baseClass}__navigationEntry {
        border-bottom-color: $_colorShiraz;
      }
    }

    &--grey {
      .#{$baseClass}__inner {
        background: $_colorShipGray;
      }

      .#{$baseClass}__navigationEntry {
        border-bottom-color: $_colorGravel;
      }
    }
  }

  .#{$baseClass}__inner {
    height: calc(100vh - 50px);
    max-width: 260px;
    background: $_colorChathamsBlueDark;
    color: $_colorWhite;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  .#{$baseClass}__navigation {
    display: flex;
    flex-direction: column;
  }

  .#{$baseClass}__navigationEntry {
    padding: 15px 20px;
    border-bottom: 1px solid $_colorChathamsBlue;
  }
}

@media (min-width: 480px) {
  .#{$baseClass} {
    background: $_colorChathamsBlue;
    transition: none;

    &--red {
      background: $_colorShiraz;

      .#{$baseClass}__inner {
        background: $_colorShirazDark;
      }

      .#{$baseClass}__navigationEntry {
        border-right-color: $_colorShiraz;

        &:hover {
          background: $_colorShiraz;
        }
      }

      .#{$baseClass}__search,
      .#{$baseClass}__logout {
        border-left-color: $_colorShiraz;
      }

      .#{$baseClass}__logout {
        &:hover {
          background: $_colorShiraz;
        }
      }
    }

    &--grey {
      background: $_colorShipGray;

      .#{$baseClass}__inner {
        background: $_colorShipGray;
      }

      .#{$baseClass}__navigationEntry {
        border-right-color: $_colorGravel;

        &:hover {
          background: $_colorGravel;
        }

        &:first-child {
          border-left: 1px solid $_colorGravel;
        }
      }

      .#{$baseClass}__search {
        border-right: 1px solid $_colorGravel;
      }

      .#{$baseClass}__search,
      .#{$baseClass}__logout {
        border-left-color: $_colorGravel;
      }

      .#{$baseClass}__logout {
        &:hover {
          background: $_colorGravel;
        }
      }
    }
  }

  .#{$baseClass}__inner {
    @include pageWidth(0);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: $_colorChathamsBlueDark;
    color: $_colorWhite;

    transition: background $_tShort ease-in-out;
  }

  .#{$baseClass}__navigation {
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: -1px;
  }

  .#{$baseClass}__navigationEntry {
    height: 50px;
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $_colorChathamsBlue;

    transition: background $_tShort ease-in-out;

    &:hover {
      background: $_colorChathamsBlue;
    }

    &--portal {
      display: none !important;
    }
  }

  .#{$baseClass}__search {
    position: relative;
    padding-left: 60px;
    height: 50px;
    flex-shrink: 0;
    align-items: center;
    border-left: 1px solid $_colorChathamsBlue;
  }

  .#{$baseClass}__searchIcon {
    position: absolute;
    z-index: 1;
    left: 25px;
    top: 50%;
    transform: translate(0, -50%);

    svg {
      display: block;
      width: 20px;
      height: 20px;
    }

    path {
      fill: $_colorWhite;
    }
  }

  .#{$baseClass}__searchInput {
    @include placeholderColor($_colorWhite);
  }
}

@media (min-width: 850px) {
  .#{$baseClass}__search {
    display: flex;
  }

  .#{$baseClass}__navigation {
    flex-grow: 0;
  }

  .#{$baseClass}__navigationEntry {
    padding: 0 30px;
    flex-grow: 0;

    height: 50px;
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $_colorChathamsBlue;

    transition: background $_tShort ease-in-out;

    &:hover {
      background: $_colorChathamsBlue;
    }

    &--logout {
      display: none;
    }
  }

  .#{$baseClass}__logout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 50px;
    flex-shrink: 0;
    border-left: 1px solid $_colorChathamsBlue;

    &:hover {
      background: $_colorChathamsBlue;
    }
  }
}
