$baseClass: 'contact';

.#{$baseClass} {
  flex-shrink: 0;
  border-top: 1px solid $_colorAlabaster;

  color: $_colorTundora;
}

.#{$baseClass}__inner {
  @include pageWidth;

  max-width: 1110px;

  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.#{$baseClass}__details {
  width: 100%;
  flex-grow: 1;
}

.#{$baseClass}__contacts {
  margin-bottom: 20px;
}

.#{$baseClass}__contact {
  margin-top: 20px;
}

.#{$baseClass}__headline {
  margin-bottom: 0;

  font-weight: 700;
  font-size: 30px;
}

.#{$baseClass}__subheadline {
  margin-bottom: 5px;
  font-weight: 600;
}

.#{$baseClass}__address {
  margin-top: 0.5em;
  line-height: 1.5;
  margin-bottom: 1em;
}

.#{$baseClass}__channels {
  line-height: 1.5;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.#{$baseClass}__link {
  font-weight: 600;
  color: $_colorShiraz;
}

.#{$baseClass}__form {
  margin-top: 30px;

  .input {
    min-width: 200px;
  }
}

@media (min-width: 480px) {
  .#{$baseClass}__form {
    min-width: 300px;
  }
}

@media (min-width: 650px) {
  .#{$baseClass}__contacts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .#{$baseClass}__contact {
    flex-grow: 1;
    flex-shrink: 0;
    width: 50%;
    padding-right: 30px;
    min-width: 250px;
  }
}

@media (min-width: 768px) {
  .#{$baseClass}__inner {
    padding-top: 50px;
    padding-bottom: 50px;
    flex-direction: row;
  }

  .#{$baseClass}__contacts {
    margin-bottom: 0;
  }

  .#{$baseClass}__details {
    width: 33%;
    flex-shrink: 0;
  }

  .#{$baseClass}__form {
    margin-top: 0;
    width: 66% !important;
  }
}
