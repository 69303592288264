.textarea {
    padding: 5px;
}

.textarea--6, .textarea--12 {
    width: 100%;
}

.textarea__inner {
    overflow: hidden;
    position: relative;
}

.textarea__field {
    width: 100% !important;
    padding: 20px 15px 12px;
    height: 41px;
    min-height: 100px;
    background: $_colorAlabaster;
    box-shadow: inset 0 0 0 21px $_colorAlabaster;
    border: 1px solid $_colorSilver;
    color: $_colorTundora;
    border-radius: 2px;
    font-size: 14px;
    overflow: hidden;
    outline: none;
    appearance: none;

    display: block;
    transition: border-color 0.25s ease-in-out;

    &:focus {
        border-color: $_colorTundora;
    }
}

.textarea__label {
    width: calc(100% - 30px);
    display: block;
    margin: 0;
    color: $_colorMidGray;
    background: $_colorAlabaster;
    transform: translate3d(15px, 12px, 0);
    position: absolute;
    z-index: 4;
    transition: all $_tShort ease-in-out;
    pointer-events: none;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.textarea__labelRequried {
    color: $_colorShiraz;
}

.no-js,
.textarea--hasContent,
.textarea--focussed {
    .textarea__label {
        font-size: 10px;
        transform: translate3d(15px, 5px, 0);
        transition: all 0.3s ease-in-out;
    }
}

// -----------------------------------------------------------------------------
//  STATE DEFINITIONS
// -----------------------------------------------------------------------------

.textarea--focussed .textarea__text {
    border-color: $_colorBlack;
}


@media (min-width: 480px) {
    .textarea--6 {
        width: 50%;
    }
}

