.footerBottom {
    position: relative;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
}

.footerBottom__inner {
    @include pageWidth;
    display: flex;
    justify-content: space-between;
}

.footerBottom__copyright {
    display: flex;
    height: 50px;
    align-items: center;
}

.footerBottom__copyrightLogo {
    margin-right: 15px;
    display: block;
    width: 24px;
    height: 26px;
}

.footerBottom__copyrightText {
    color: $_colorTundora;
    font-size: 10px;
}

.footerBottom__social {
    display: none;
}

.footerBottom__socialLink {
    svg {
        display: block;
        width: 30px;
        height: 30px;
        fill: $_colorMineShaft;
    }
}

@media (min-width: 480px) {
    .footerBottom__social {
        display: flex;
        height: 50px;
        align-items: center;
    }

    .footerBottom__copurightText {
        font-size: 12px;
    }

    .footerBottom__socialText {
        color: $_colorTundora;
    }

    .footerBottom__socialLinks {

    }

    .footerBottom__socialLink {
        margin-left: 10px;
        display: block;
    }
}