.checkbox {
    display: flex;
    width: 100%;
    padding: 5px;
    cursor: pointer;

    & + & {
        margin-top: 5px;
    }

    &--small {
        align-items: center;
        width: auto;

        .checkbox__label {
            font-size: 12px;
        }
    }
}

.checkbox__checkbox {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    flex-shrink: 0;

    &::before,
    &::after {
        content: '';
        position: absolute;
        box-sizing: border-box;
    }

    &::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $_colorSilver;
        background: $_colorAlabaster;

        transition: border-color 0.25s ease-in-out;
    }

    &::after {
        top: 4px;
        left: 4px;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        background: $_colorMidGray;
        opacity: 0;

        transition: opacity $_tShort ease-in-out;
    }
}

.checkbox__native {
    position: absolute;
    left: -9999px;
    opacity: 0;

    &:checked {
        & + .checkbox__checkbox {
            &::after {
                opacity: 1;
            }
        }
    }

    &:focus {
        & + .checkbox__checkbox {
            &::before {
                border-color: $_colorTundora;
            }
        }
    }

    &:invalid {
        &:focus {
            & + .checkbox__checkbox {
                &::before {
                    border-color: $_colorShiraz;
                }
            }

            & ~ .checkbox__label {
                color: $_colorShiraz;
            }
        }
    }
}

.checkbox__label {

}
