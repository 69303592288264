$baseClass: 'magazineHome';

.#{$baseClass}__hero {
  display: none;
}

@media (min-width: 768px) {
  .#{$baseClass}__hero {
    display: block;
  }
}
