$baseClass: 'bulletinList';

.#{$baseClass} {
  @include pageWidth;

  padding-top: 25px;
  padding-bottom: 25px;
  color: $_colorTundora;
}

.#{$baseClass}__headerTitle {
  margin-bottom: 15px;

  font-size: 30px;
  font-weight: 700;
}

.#{$baseClass}__headerText {
  max-width: 990px;
  line-height: 1.5;

  @include wysiwyg;
}

.#{$baseClass}__headerFilter {
  margin: 15px -5px 25px;
}

.#{$baseClass}__body {
  border-top: 1px solid $_colorSilver;
}

.#{$baseClass}__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.#{$baseClass}__item {
  padding: 25px 0;
  max-width: 870px;

  & + & {
    border-top: 1px solid $_colorSilver;
  }

  &--hidden {
    display: none;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.#{$baseClass}__itemHeader {
  margin-bottom: 15px;
}

.#{$baseClass}__itemHeaderInner {
  flex-grow: 1;
}

.#{$baseClass}__itemDate {
  font-size: 14px;
}

.#{$baseClass}__itemHeadline {
  font-size: 20px;
  font-weight: 600;
}

.#{$baseClass}__itemHeaderFiles {
  margin-top: 10px;
}

.#{$baseClass}__itemHeaderButton {
  padding-left: 45px;
  padding-right: 45px;
}

.#{$baseClass}__itemDescription {
  @include wysiwyg;

  line-height: 1.5;
}

@media (min-width: 480px) {
  .#{$baseClass} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .#{$baseClass}__headerTitle {
    font-size: 40px;
  }

  .#{$baseClass}__headerFilter {
    max-width: 300px;
  }

  .#{$baseClass}__itemHeader {
    display: flex;
  }

  .#{$baseClass}__itemHeaderFiles {
    flex-shrink: 0;
    margin-left: 16px;
  }

  .#{$baseClass}__itemHeaderButton {
    max-width: 180px;
  }
}

@media (min-width: 768px) {
  .#{$baseClass}__itemDescription {
    width: calc(100% - 200px);
  }
}
