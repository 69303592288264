.google {
    position: relative;

    &--active {
        .google__map {
            pointer-events: all;
        }

        .google__blocker {
            display: none;
        }

        .google__buttonInner {
            &--on {
                display: none;
            }

            &--off {
                display: block;
            }
        }
    }
}

.google__map {
    pointer-events: none;

    width: 100%;
    height: 400px;
}

.google__blocker{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.google__blocker {
    z-index: 1;

    background: rgba(0, 0, 0, 0.3);
    transition: background $_tShort ease-in-out;
}

.google__button {
    position: absolute;
    z-index: 2;
    top: 30px;
    right: 30px;
    background: $_colorShiraz;

    border-radius: 50%;

    cursor: pointer;
    transition: background $_tShort ease-in-out;

    &:hover {
        background: lighten($_colorShiraz, 10);
    }
}

.google__buttonInner {
    width: 40px;
    height: 40px;

    position: relative;

    svg {
        display: block;
        fill: $_colorWhite;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--on {
        svg {
            width: 20px;
            height: 17px;
        }
    }

    &--off {
        display: none;

        svg {
            width: 26px;
            height: 22px;
        }
    }
}

@media (min-width: 480px) {
    .google__map {
        height: 620px;
    }
}
