.page {
}

.page__inner {
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
}

.page__headline {
    max-width: 800px;
    margin-bottom: 15px;

    font-weight: 600;
    font-size: 24px;
    color: $_colorTundora;
}

.page__content {
    max-width: 800px;
    color: $_colorTundora;
    @include wysiwyg;

    blockquote {
        border-left: 3px solid $_colorGallery;
        margin: 0;
        padding: 5px 0 5px 20px;
        max-width: 910px;
    }

    p {
        margin: 0;
        max-width: 910px;
    }

    h1, h2, h3, h4, h5, p, ul, ol, blockquote {
        & + h1, & + h2, & + h3, & + h4, & + h5,& +  p, & + ul, & + ol, & + blockquote {
            margin-top: 1em;
        }
    }
}

@media (min-width: 480px) {
    .page__inner {
        padding: 30px 25px;
    }

    .page__headline {
        font-size: 30px;
    }
}