$baseClass: 'footer';

.#{$baseClass} {
  overflow: hidden;
}

@media print {
  .#{$baseClass} {
    display: none;
  }
}
