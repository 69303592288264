.flashMessages {
    width: 100%;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;

    opacity: 0;
    pointer-events: none;
    text-align: center;

    transition: opacity $_tShort ease-in-out;
}

.flashMessages--visible {
    opacity: 1;
    pointer-events: all;
    }

.flashMessages__item {
    max-width: 1000px;
    display: inline-block;
    position: relative;
    background: $_colorTundora;
    margin: 5px 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
    border-radius: 3px;
}

.flashMessages__item--error {
    background: $_colorShiraz;
}

.flashMessages__item--success {
    background: $_colorMountainMeadow;
}

.flashMessages__itemClose {
    position: absolute;
    top: 13px;
    right: 15px;
    cursor: pointer;

    svg {
        display: block;
        width: 15px;
        height: 15px;
    }

    path {
        fill: $_colorWhite;
    }
}

.flashMessages__itemText {
    color: $_colorWhite;
    padding: 10px 45px 10px 20px;

    a {
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
    }
}
