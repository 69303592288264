@mixin ellipsis() {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin pageWidth($padding: 20px) {
    max-width: $_rowWidth;
    margin: 0 auto;

    @media (max-width: $_rowWidth + 2 * $padding) {
        padding-left: $padding;
        padding-right: $padding;
    }
}

@mixin removeScrollbar {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        position: absolute;
        width: 0 !important;
        height: 0 !important;
    }
}

@mixin placeholderColor($color) {
    &::-webkit-input-placeholder {
        color: $color;
        opacity: 1;
        font-weight: 400;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: $color;
        opacity: 1;
        font-weight: 400;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        color: $color;
        opacity: 1;
        font-weight: 400;
    }

    &:-ms-input-placeholder {
        color: $color !important;
    }

    &::-ms-input-placeholder { /* Edge */
        color: $color;
        opacity: 1;
        font-weight: 400;

    }

    &::placeholder { /* CSS Working Draft */
        color: $color;
        opacity: 1;
        font-weight: 400;
    }
}

@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin wysiwyg {
    overflow: hidden;
    text-overflow: ellipsis;

    a {
        font-weight: 800;
        color: $_colorShiraz;
    }

    b, strong {
        font-weight: 800;
        color: $_colorMineShaft;
    }

    i, em {
        font-style: italic;
    }

    p, ul, ol {
        margin: 0;
        line-height: 1.5;

        & + p,
        & + ul,
        & + ol,
        & + .button {
            margin-top: 1em;
        }
    }
}
