$baseClass: 'headerTop';

.#{$baseClass} {
  position: relative;
  z-index: 2;
  background: $_colorWhite;

  &--magazine {
    .#{$baseClass}__logoText {
      font-size: 12px;

      strong {
        display: block;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        color: $_colorMineShaft;
      }
    }

    .#{$baseClass}__searchInner {
      background: $_colorShipGray;
    }

    .#{$baseClass}__searchInput {
      color: $_colorAmaethystSmoke;
    }

    .#{$baseClass}__loginIcon svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
}

.#{$baseClass}__inner {
  @include pageWidth;

  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.#{$baseClass}__logo {
  display: flex;
  align-items: center;
}

.#{$baseClass}__logoImage {
  display: block;
  height: 26px;
}

.#{$baseClass}__logoText {
  margin-left: 8px;
  font-size: 19px;
  letter-spacing: -0.5px;
}

.#{$baseClass}__menuTrigger {
  position: relative;
  width: 24px;
  padding: 8px 0;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    border-top: 3px solid $_colorTundora;

    transition: transform $_tShort ease-in-out;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.#{$baseClass}__menuTriggerMiddle {
  border-top: 3px solid $_colorTundora;
  transition: opacity $_tShort ease-in-out;
}

.#{$baseClass}__menuTrigger--menu {
  .#{$baseClass}__menuTriggerMiddle {
    opacity: 0;
  }

  &::before {
    transform: translate(0, 8px) rotate(-45deg);
  }

  &::after {
    transform: translate(0, -8px) rotate(45deg);
  }
}

.#{$baseClass}__options {
  height: 50px;
  display: flex;
  align-items: center;
}

.#{$baseClass}__searchTrigger {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 16px;
  border-left: 1px solid $_colorGallery;
  cursor: pointer;

  svg {
    display: block;
    width: 25px;
    height: 21px;
  }

  path {
    fill: $_colorTundora;
  }
}

.#{$baseClass}__search {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;

  opacity: 0;
  transform: translate(100%, 0);
  pointer-events: none;

  transition: opacity $_tShort ease-in-out, transform $_tShort ease-in-out;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 200vw;
    height: 110vh;
    background: $_colorLayer;
  }
}

.#{$baseClass}__search--open {
  opacity: 1;
  transform: translate(0, 0);
  pointer-events: all;
}

.#{$baseClass}__searchInner {
  padding: 0 20px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 50px;
  background: $_colorChathamsBlueDark;
  color: $_colorWhite;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.#{$baseClass}__searchIcon {
  cursor: pointer;

  svg {
    display: block;
    width: 25px;
    height: 21px;
  }

  path {
    fill: $_colorWhite;
  }
}

.#{$baseClass}__searchInput {
  padding: 0 25px;
  flex-grow: 1;
  font-size: 14px;
  color: $_colorHummingBird;
}

.#{$baseClass}__searchClose {
  cursor: pointer;

  svg {
    display: block;
    width: 20px;
    height: 20px;
  }

  path {
    fill: $_colorWhite;
  }
}

.#{$baseClass}__login {
  display: none;
  align-items: center;
  height: 100%;

  path {
    fill: $_colorTundora;
  }
}

.#{$baseClass}__loginIcon {
  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}

@media (min-width: 480px) {
  .#{$baseClass}__menuTrigger {
    display: none;
  }

  .#{$baseClass}__searchTrigger {
    margin-left: 25px;
  }

  .#{$baseClass}__login {
    display: flex;
  }
}

@media (min-width: 550px) {
  .#{$baseClass} {
    &--magazine {
      .#{$baseClass}__logoText {
        font-size: 14px;

        strong {
          display: inline;
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: 850px) {
  .#{$baseClass}__searchTrigger {
    display: none;
  }
}
