.usps {
    margin: 35px 0 20px;
}

.usps__inner {
    @include pageWidth;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.usps__item {
    width: 100%;
    padding-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
}

.usps__itemImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    box-shadow: 0 0 3px 0 rgba(0,0,0,1);
}

.usps__itemContent {
    width: 100%;
    flex-grow: 1;
    text-align: center;
}

.usps__itemHeadline {
    margin-top: 15px;
    text-align: center;

    font-weight: 600;
    font-size: 24px;
    color: $_colorTundora;
}

.usps__itemText {
    margin-top: 20px;

    font-size: 16px;
    color: $_colorTundora;

    p {
        line-height: 1.5;
    }
}

@media (min-width: 480px) {
    .usps__itemHeadline {
        margin-top: 0;
        display: flex;
        align-items: center;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .usps__itemImage {
        width: 100px;
        height: 100px;
    }

    .usps__item {
        flex-direction: row;
    }

    .usps__itemContent {
        text-align: left;
        margin-left: 30px;
    }

    .usps__itemHeadline {
        text-align: left;
        margin-top: 0;
    }
}

@media (min-width: 768px) {
    .usps__inner {
        flex-direction: row;
    }

    .usps__item {
        padding: 20px 30px 40px;
        width: 33.333%;
    }

    .usps__itemImage {
        width: 130px;
        height: 130px;
    }

    .usps__itemHeadline {
        margin-top: 25px;
        min-height: 2.7em;
        justify-content: center;
    }
}
