.productReview {
}

.productReview__inner {
    @include pageWidth;
    max-width: 1110px;
    padding-top: 30px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.productReview__headline {
    margin-bottom: 20px;

    font-weight: 600;
    font-size: 24px;
    color: $_colorTundora;
    text-align: center;
}

.productReview__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.productReview__image {
    margin-bottom: 25px;

    img {
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 3px 0 rgba(0,0,0,1);
    }
}

.productReview__text {
    margin-bottom: 15px;

    font-weight: 600;
    font-size: 16px;
    color: $_colorTundora;

    @include wysiwyg;
}

.productReview__author {
    font-size: 14px;
    color: $_colorTundora;
}


@media (min-width: 480px) {
    .productReview__content {
        flex-wrap: nowrap;
    }

    .productReview__image {
        margin: 0 40px 0 0;
    }

    .productReview__headline {
        margin-bottom: 30px;

        font-size: 40px;
        text-align: left;
    }

    .productReview__text {
        margin-bottom: 20px;

        font-size: 18px;
    }

    .productReview__author {
        font-size: 16px;
    }
}

@media (min-width: 1000px) {
    .productReview__image {
        margin-right: 70px;
    }
}
