.tiles {
    background: $_colorAlabaster;
    color: $_colorTundora;

    &--product {
        .tiles__inner {
            max-width: 1110px;
            padding: 0 0 35px;

            @media (max-width: 1340px) {
                padding: 20px 15px 50px;
            }
        }
    }
}

.tiles__inner {
    @include pageWidth(0);

    overflow: hidden;
    padding: 10px 5px;
}

.tiles__title {
    margin: 0 0 20px;
    padding-top: 10px;
    border-top: 3px solid $_colorAlto;

    font-weight: 600;
    font-size: 30px;
}

.tiles__tiles {
    opacity: 0;
    transition: opacity $_tShort ease-in-out;

    &--initialized {
        opacity: 1;
    }
}

.tiles__tile {
    position: relative;
    display: block;
    width: 100%;
    padding: 5px;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background: rgba(255, 255, 255, 0.1);
    }

    &--color {
        &::before {
            content: none;
        }

        .tiles__tileHeadline {
            display: block;
            color: $_colorWhite;
            font-size: 25px;
            background: none;

            svg {
                position: absolute;
                top: 20px;
                left: 17px;
                width: 20px;
                fill: $_colorWhite;
            }
        }

        .tiles__tileContent {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0 10px;
        }
    }
}

.tiles__tileInner {
    position: relative;
    z-index: 1;
    padding-bottom: 81.5%;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.18);

    background-color: $_colorWhite;
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.tiles__tileContent {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.tiles__tileHeadline {
    display: flex;
    align-items: flex-start;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    padding: 15px 17px 20px;

    font-weight: 600;
    font-size: 20px;

    background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0) 100%);

    transition: opacity $_tShort ease-in-out;

    svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        top: 0.15em;

        flex-shrink: 0;
        margin: 0 8px 0 0;
        position: relative;
        fill: $_colorTundora;
    }
}

.tiles__tile--event {
    .tiles__tileHeadline {
        svg {
            width: 18px;
            height: 18px;
        }
    }
}

.tiles__tileHeadlineText {
    display: inline-block;
}

.tiles__tileOverlay {
    display: none;
}




@media (min-width: 480px) {
    .tiles__tile {
        &--1x2,
        &--1x1 {
            .tiles__tileHeadline {
                font-size: 16px;

                svg {
                    width: 16px;
                    height: 16px;
                    top: 0.15em;
                }
            }

            &.tiles__tile--event {
                .tiles__tileHeadline {
                    svg {
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }

        &--2x2 {
            .tiles__tileHeadline {
                font-size: 25px;

                svg {
                    top: 0.3em;
                }
            }
        }

        &--color {
            svg {
                top: 16px !important;
            }
        }
    }

    .tiles__tile--1x1 {
        width: 50%;

        .tiles__tileOverlayText {
            margin-top: 10px;
        }
    }

    .tiles__tile--2x2 {
        width: 100%;
    }

    .tiles__tile--1x2 {
        width: 100%;

        .tiles__tileInner {
            padding-bottom: 40%;
        }
    }

    .tiles__tileInner {
        &:hover {
            .tiles__tileOverlay {
                opacity: 1;
            }

            .tiles__tileOverlayContent {
                transform: translate(-50%, -50%);
            }

            .tiles__tileHeadline {
                opacity: 0;
            }
        }
    }

    .tiles__tileOverlay {
        display: block;
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 2;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        overflow: hidden;
        opacity: 0;
        background: rgba(0, 0, 0, 0.7);

        color: $_colorWhite;
        text-align: center;

        transition: opacity $_tShort ease-in-out;
    }

    .tiles__tileOverlayContent {
        width: 100%;
        max-width: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -55%, 0);
        transition: transform $_tShort ease-in-out;
        padding: 20px 10px;
    }

    .tiles__tileOverlayHeader {
        padding-bottom: 10px;
    }

    .tiles__tileOverlayHeadline {
        font-weight: 600;
        font-size: 20px;
    }

    .tiles__tileOverlayText {
        margin-top: 20px;

        font-size: 16px;

        p {
            margin: 0;
        }
    }

    .tiles__tileOverlayButton {
        margin: 15px auto 0;
        max-width: 240px;
    }
}

@media (min-width: 480px) {
    .tiles__title {
        margin: 10px 0 25px;

        font-size: 35px;
    }
}

@media (min-width: $_wLarge) {
    .tiles__tile--1x1 {
        width: 33.333%;
    }

    .tiles__tile--2x2,
    .tiles__tile--1x2 {
        width: 66.666%;
    }
}

@media (min-width: 900px) {
    .tiles__tile--1x1 {
        width: 25%;
    }

    .tiles__tile--2x2,
    .tiles__tile--1x2 {
        width: 50%;
    }
}
