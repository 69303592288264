$baseClass: 'documentsArchive';

.#{$baseClass} {
  @include pageWidth;

  padding-top: 25px;
  color: $_colorTundora;
}

.#{$baseClass}__headerTitle {
  margin-bottom: 15px;

  font-size: 30px;
  font-weight: 700;
}

.#{$baseClass}__headerText {
  margin-bottom: 25px;
  max-width: 990px;
  line-height: 1.5;

  @include wysiwyg;
}

.#{$baseClass}__body {
  border-top: 1px solid $_colorSilver;
}

.#{$baseClass}__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.#{$baseClass}__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0;
  max-width: 870px;
  border-bottom: 1px solid $_colorSilver;
}

.#{$baseClass}__itemBody {
  flex-grow: 999;
  padding-right: 15px;
}

.#{$baseClass}__itemBreadcrumbs {
  margin-bottom: 8px;
  line-height: 1;
}

.#{$baseClass}__itemDate {
  font-size: 14px;
}

.#{$baseClass}__itemHeadline {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
  padding: 2px 0 5px;
}

.#{$baseClass}__itemFile {
  font-size: 14px;
}

.#{$baseClass}__itemButtons {
  width: 100%;
  flex-grow: 1;
}

.#{$baseClass}__itemButton {
  margin-top: 15px;
  margin-bottom: 0;
  padding-left: 45px;
  padding-right: 45px;
}

.#{$baseClass}__itemImageWrapper {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
}

.#{$baseClass}__itemImage {
  display: block;
  width: 100%;
}

@media (min-width: 480px) {
  .#{$baseClass} {
    padding-top: 50px;
    padding-bottom: 25px;
  }

  .#{$baseClass}__headerTitle {
    font-size: 40px;
  }

  .#{$baseClass}__item {
    flex-wrap: nowrap;
  }

  .#{$baseClass}__itemHeader {
    display: flex;
  }

  .#{$baseClass}__itemButtons {
    width: auto;
  }

  .#{$baseClass}__itemButton {
    margin-top: 0;
  }

  .#{$baseClass}__itemImageWrapper {
    margin-right: 25px;
  }
}
