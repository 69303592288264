$baseClass: 'dashboardBulletinsDocuments';

.#{$baseClass} {
  overflow: hidden;
  background: $_colorGallery;
  color: $_colorTundora;
}

.#{$baseClass}__inner {
  @include pageWidth;
}

.#{$baseClass}__section {
  margin: 25px 0;
}

.#{$baseClass}__sectionInner {
  padding: 25px 20px 10px;

  background: $_colorWhite;
}

.#{$baseClass}__headline {
  font-size: 25px;
  font-weight: 700;
}

.#{$baseClass}__bulletins {
  margin: 0;
  padding: 0;
  list-style: none;
}

.#{$baseClass}__bulletin {
  padding: 20px 0;
  border-bottom: 1px solid $_colorSilver;
}

.#{$baseClass}__bulletinDate {
  font-size: 14px;
}

.#{$baseClass}__bulletinHeadline {
  margin-bottom: 15px;

  font-size: 20px;
  font-weight: 600;
}

.#{$baseClass}__bulletinDescription {
  @include wysiwyg;

  line-height: 1.5;
}

.#{$baseClass}__bulletinButton {
  margin-top: 25px;
  padding-left: 35px;
  padding-right: 35px;
}

.#{$baseClass}__documents {
  margin: 0;
  padding: 0;
  list-style: none;
}

.#{$baseClass}__document {
  padding: 15px 0;
  border-bottom: 1px solid $_colorSilver;
}

.#{$baseClass}__documentDate {
  font-size: 14px;
}

.#{$baseClass}__documentHeadline {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 1.3;
}

.#{$baseClass}__documentBody {
  flex-grow: 1;
}

.#{$baseClass}__documentFiles {
  margin: 0;
  padding: 0;
  list-style: none;
}

.#{$baseClass}__documentImageWrapper {
  margin-top: 10px;
  flex-shrink: 0;
  width: 67px;
  height: 67px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  background: $_colorWhite;
}

.#{$baseClass}__documentImage {
  display: block;
  width: 100%;
}

.#{$baseClass}__documentButton {
  margin-top: 25px;
  flex-shrink: 0;
  padding-left: 35px;
  padding-right: 35px;
}

@media (min-width: 480px) {
  .#{$baseClass} {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .#{$baseClass}__bulletin,
  .#{$baseClass}__document {
    display: flex;
  }

  .#{$baseClass}__headline {
    font-size: 30px;
  }

  .#{$baseClass}__documentsButton {
    display: flex;
    justify-content: flex-end;
  }

  .#{$baseClass}__documentImageWrapper {
    margin: 0 0 0 15px;
  }
}

@media (min-width: 768px) {
  .#{$baseClass}__inner {
    display: flex;
  }

  .#{$baseClass}__section {
    width: 50%;
    padding-right: 10px;

    & + & {
      padding-right: 0;
      padding-left: 10px;
    }
  }

  .#{$baseClass}__sectionInner {
    padding: 25px 35px 10px;
  }

  .#{$baseClass}__bulletin {
    display: block;
  }
}

@media (min-width: 1024px) {
  .#{$baseClass}__bulletin {
    display: flex;
  }
}
