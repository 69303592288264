$baseClass: 'magazineCategoriesTile';

.#{$baseClass} {
  padding: 25px 10px;
}

.#{$baseClass}__inner {
  height: 100%;
  padding: 35px 66px 50px 33px;
  background: $_colorShipGray;
  color: $_colorWhite;
}

.#{$baseClass}__title {
  color: $_colorAmaethystSmoke;
}

.#{$baseClass}__intro {
  font-size: 24px;
  line-height: 33px;
  font-weight: 600;
  letter-spacing: 0;
}

.#{$baseClass}__categories {
  margin-top: 20px;
  list-style: none;
  padding-left: 0;
}

.#{$baseClass}__category {
  font-size: 18px;
  line-height: 36px;

  &:hover {
    text-decoration: underline;
  }
}

@media (min-width: 480px) {
  .#{$baseClass} {
    padding: 25px;
  }
}
