$baseClass: 'formSlider';

.#{$baseClass} {
  overflow: hidden;
}

.#{$baseClass}__inner {
  display: flex;
  width: 200%;

  transition: transform $_tShort ease-in-out;

  &--reset {
    transform: translate(-50%, 0);
  }
}

.#{$baseClass}__form {
  width: 50%;

  &--active {
    .#{$baseClass}__formInner {
      opacity: 1;

      transition: opacity $_tShort ease-in-out $_tXShort;
    }
  }
}

.#{$baseClass}__formInner {
  height: 100%;
  display: flex;
  flex-direction: column;

  opacity: 0;
  transition: opacity $_tShort ease-in-out;
}

.#{$baseClass}__headline {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;

  & + .#{$baseClass}__form {
    margin-top: 25px;
  }
}

.#{$baseClass}__formContent {
  flex-grow: 1;
}

.#{$baseClass}__text {
  margin: 10px 0;
}

.#{$baseClass}__linkWrapper {
  text-align: center;
}

.#{$baseClass}__button {
  margin: 10px 0 5px !important;
  max-width: 100% !important;
}
