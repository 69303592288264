$baseClass: 'documentsList';

.#{$baseClass} {
  @include pageWidth;

  padding-top: 25px;
  padding-bottom: 25px;
  color: $_colorTundora;
}

.#{$baseClass}__headerTitle {
  margin-bottom: 15px;

  font-size: 30px;
  font-weight: 700;
}

.#{$baseClass}__headerText {
  max-width: 990px;
  line-height: 1.5;

  @include wysiwyg;
}

.#{$baseClass}__headerFilters {
  margin: 15px -5px 15px;
}

.#{$baseClass}__headerInput {
  margin-bottom: 10px;
}

.#{$baseClass}__body {
  border-top: 1px solid $_colorSilver;
}

.#{$baseClass}__list {
  margin: 25px 0 0;
  padding: 0;
  list-style: none;
}

.#{$baseClass}__document {
  padding: 12px 15px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
  background: $_colorWhite;

  &--dark {
    background: $_colorGallery;
  }

  & + & {
    margin-top: 15px;
  }

  &--open {
    & > .#{$baseClass}__documentHeadline {
      svg {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }

    & > .#{$baseClass}__documentBody {
      max-height: none;
    }
  }

  &--hidden {
    display: none !important;
  }

  &--show {
    display: block;

    & > .#{$baseClass}__documentHeadline {
      svg {
        transform: rotate(90deg);
        transform-origin: center;
      }
    }

    & > .#{$baseClass}__documentBody {
      max-height: none;
    }
  }
}

.#{$baseClass}__documentBody {
  max-height: 0;
  overflow: hidden;
}

.#{$baseClass}__documentBodyInner {
  padding: 1px;
}

.#{$baseClass}__documentHeadline {
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;

  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: 2px 3px 0 -6px;
    flex-shrink: 0;
    transition: transform $_tShort ease-in-out;
    fill: $_colorTundora;
  }
}

.#{$baseClass}__documentHeadlineCount {
  display: none;
  margin-left: 8px;
  font-size: 20px;
}

.#{$baseClass}__documentSubheadline {
  padding: 2px 0 15px 23px;
  border-bottom: 1px solid $_colorSilver;

  font-size: 14px;
}

.#{$baseClass}__documentFiles {
  margin: 0;
  padding: 0;
  list-style: none;
}

.#{$baseClass}__documentFile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 0;

  &:not(.#{$baseClass}__documentFile--hidden) + & {
    border-top: 1px solid $_colorSilver;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &--hidden {
    display: none !important;
  }
}

.#{$baseClass}__documentFileInner {
  flex-grow: 1;
  margin-right: 15px;
  overflow: hidden;
}

.#{$baseClass}__documentFileDate {
  font-size: 14px;
}

.#{$baseClass}__documentFileName {
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.#{$baseClass}__documentFileFile {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.#{$baseClass}__documentChildren {
  margin: 15px 0 5px;
  padding: 0;
  list-style: none;

  &:empty {
    display: none;
  }
}

.#{$baseClass}__documentFileButtons {
  width: 100%;
}

.#{$baseClass}__documentFileButton {
  margin: 10px 0 0;
  flex-shrink: 0;
  padding-left: 45px;
  padding-right: 45px;
}

.#{$baseClass}__documentImageWrapper {
  margin-right: 0;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  background: $_colorWhite;
}

.#{$baseClass}__documentImage {
  display: block;
  width: 100%;
}

@media (min-width: 480px) {
  .#{$baseClass} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .#{$baseClass}__headerTitle {
    font-size: 40px;
  }

  .#{$baseClass}__headerFilters {
    display: flex;
    align-items: center;
    margin: 15px -5px 25px;
  }

  .#{$baseClass}__headerInput {
    max-width: 300px;
    flex-grow: 1;
    margin-bottom: 0;
    margin-right: 50px;
  }

  .#{$baseClass}__headerCheckbox {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 25px;
  }

  .#{$baseClass}__documentHeadline {
    font-size: 25px;

    svg {
      margin-top: 5px;
    }
  }

  .#{$baseClass}__list {
    margin-top: 35px;
  }

  .#{$baseClass}__document {
    padding: 12px 20px;
  }

  .#{$baseClass}__documentHeadlineCount {
    display: inline-block;
    align-self: center;
  }

  .#{$baseClass}__documentFile {
    flex-wrap: nowrap;
  }

  .#{$baseClass}__documentFileButtons {
    width: auto;
  }

  .#{$baseClass}__documentFileButton {
    margin-top: 5px;
  }

  .#{$baseClass}__documentImageWrapper {
    margin-right: 25px;
  }
}
