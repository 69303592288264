$baseClass: 'magazineArticleSidebox';

.#{$baseClass} {
    color: $_colorWhite;
}

.#{$baseClass}__container {
    padding: 35px 25px;
    background: $_colorShipGray;
    font-size: 16px;

    & + & {
        margin-top: 25px;
    }
}

.#{$baseClass}__author {
    display: flex;
    align-items: center;
}

.#{$baseClass}__authorImageWrapper {
    position: relative;
    width: 66px;
    height: 66px;
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        display: block;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: $_colorAlabaster;
        border-radius: 50%;
        overflow: hidden;
    }
}

.#{$baseClass}__authorImage {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    filter: saturate(0);
}

.#{$baseClass}__authorText {
    margin-left: 21px;
}

.#{$baseClass}__authorTitle {
    color: $_colorAmaethystSmoke;
    font-weight: 600;
}

.#{$baseClass}__authorName {
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: 700;
}

.#{$baseClass}__text {
    margin-top: 25px;
    line-height: 1.5;

    ul {
        padding-left: 1.3em;
    }

    li + li {
        margin-top: 8px;
    }
}

.#{$baseClass}__linksHeadline {
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;
    letter-spacing: 0;
}

.#{$baseClass}__links {
    margin-top: 20px;
    line-height: 1.5;
}

.#{$baseClass}__link {
    &:hover {
        text-decoration: underline;
    }

    & + & {
        margin-top: 6px;
    }
}

.#{$baseClass}__shareText {
    font-size: 18px;
    font-weight: 600;
}

.#{$baseClass}__icons {
    margin-top: 25px;
    display: flex;
}

.#{$baseClass}__icon {
    width: 40px;
    color: $_colorAmaethystSmoke;

    &:hover {
        color: $_colorWhite;
    }

    & + & {
        margin-left: 16px;
    }

    svg {
        display: block;
        width: 100%;
    }
}

.#{$baseClass}__print {
    display: none;
    justify-content: flex-end;
    margin-top: 25px;
}

.#{$baseClass}__printButton {
    padding: 0;
    background: none;
    border: none;
    appearance: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $_colorAmaethystSmoke;

    &:hover {
        color: $_colorShipGray;
    }
}

.#{$baseClass}__printIcon {
    width: 30px;

    svg {
        display: block;
        width: 100%;
        height: auto;
    }
}

.#{$baseClass}__printText {
    margin-left: 10px;
}

@media (min-width: 480px) {
    .#{$baseClass}__print {
        display: flex;
    }
}

@media (min-width: 950px) {
    .#{$baseClass} {
        position: sticky;
        top: 100px;
    }

    .#{$baseClass}__inner {
        max-height: calc(100vh - 100px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .#{$baseClass}__container {
        padding: 33px 33px 60px;
    }
}

@media print {
    .#{$baseClass} {
        margin: 0 auto;
        max-width: 400px;
    }

    .#{$baseClass}__container {
        break-inside: avoid;
    }

    .#{$baseClass}__container--sharing,
    .#{$baseClass}__container--links,
    .#{$baseClass}__print {
        display: none;
    }

    .#{$baseClass}__authorName {
        font-size: 20px;
    }

    .#{$baseClass}__text {
        font-size: 14px;
    }
}
