.intro__inner {
  padding: 30px 20px;
  max-width: 800px;
  margin: 0 auto;

  text-align: center;
  color: $_colorTundora;
}

.intro__headline {
  margin-bottom: 10px;

  font-weight: 700;
  font-size: 30px;
  line-height: 1.1;
}

.intro__text {
  @include wysiwyg;

  line-height: 1.5;
}

@media (min-width: 480px) {
  .intro__inner {
    padding: 50px 20px;
  }

  .intro__headline {
    margin-bottom: 20px;

    font-size: 40px;
  }

  .intro__text {
    font-size: 20px;
  }
}
