$baseClass: 'account';

.#{$baseClass} {
  @include pageWidth;

  padding-top: 25px;
  padding-bottom: 25px;
  color: $_colorTundora;
}

.#{$baseClass}__headerTitle {
  margin-bottom: 15px;

  font-size: 30px;
  font-weight: 700;
}

.#{$baseClass}__headerText {
  margin-bottom: 25px;
  max-width: 990px;
  line-height: 1.5;

  @include wysiwyg;
}

.#{$baseClass}__body {
  padding-top: 30px;
  border-top: 1px solid $_colorSilver;
}

.#{$baseClass}__headline {
  margin-block-end: 10px;
}

.#{$baseClass}__text {
  margin: 0 5px;
  font-size: 14px;
}

.#{$baseClass}__form {
  display: flex;
  align-items: center;
}

.#{$baseClass}__formInner {
  width: 100%;
}

@media (max-width: 767px) {
  .#{$baseClass}__column {
    display: flex;
    flex-direction: column;

    &--right {
      .#{$baseClass}__form {
        margin-top: 25px;
      }
    }
  }
}

@media (min-width: 768px) {
  .#{$baseClass} {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .#{$baseClass}__headerTitle {
    font-size: 40px;
  }

  .#{$baseClass}__body {
    display: flex;
  }

  .#{$baseClass}__column {
    &--left {
      flex-grow: 999;
      margin-right: 12px;
      width: 75%;
      max-width: 800px;

      .#{$baseClass}__form {
        height: 100%;
      }
    }

    &--right {
      flex-grow: 1;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      width: 25%;
      min-width: 300px;
    }
  }

  .#{$baseClass}__form {
    flex-grow: 1;

    & + & {
      margin-top: 25px;
    }
  }
}
