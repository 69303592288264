$baseClass: 'NotificationDetails';

.#{$baseClass} {
    background: $_colorWhite;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    margin: 15px 15px 15px 0;
    padding: 15px;
}

.#{$baseClass}__title {
    margin: 0 0 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid $_colorAlto;
}

.#{$baseClass}__actions {
    display: flex;
}

.#{$baseClass}__form {
    & + & {
        margin-left: 8px;
    }
}

.#{$baseClass}__subtitle {
    margin: 25px 0 10px;
}

.#{$baseClass}__table {
    width: 100%;
    margin: 0;

    & + & {
        margin-top: 25px;
    }
}

.#{$baseClass}__th,
.#{$baseClass}__td {
    text-align: center;

    &--left {
        text-align: left;
    }
}


