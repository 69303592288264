.sections {
  color: $_colorTundora;

  .form__form {
    margin-top: 0;
  }
}

.sections__section {
  overflow: hidden;
  padding: 30px 0;

  &--grey {
    background: $_colorAlabaster;

    & + & {
      padding-top: 0;
    }
  }

  &--white {
    & + & {
      padding-top: 0;
    }
  }

  .customContact__form {
    background: #ffffff;
  }
}

.sections__inner {
  @include pageWidth;

  &--one-column-image,
  &--two-column-left,
  &--two-column-right,
  &--two-column-text,
  &--two-column-contact {
    .sections__content {
      width: 100%;
    }

    .sections__image {
      width: 100%;
    }
  }

  &--one-column-image {
    margin: -30px auto;
    padding: 0;

    .sections__content {
      display: none;
    }

    .sections__image {
      margin-top: 0;
    }
  }

  &--two-column-text {
    .sections__content {
      & + .sections__content {
        margin-top: 30px;
      }
    }
  }
}

.sections__headline {
  margin-bottom: 20px;

  font-weight: 700;
  font-size: 30px;
  line-height: 1.1;
  color: $_colorMineShaft;

  &:empty {
    display: none;
  }
}

.sections__text {
  font-size: 18px;
  color: $_colorTundora;
  @include wysiwyg;
}

.sections__image {
  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  }
}

.sections__imageCaption {
  margin: 8px;

  text-align: center;
  font-size: 14px;
}

.sections__youtube {
  width: 100%;
  padding-bottom: 56.35%;
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.sections__downloads {
  margin-top: 25px;
}

.sections__download {
  flex-shrink: 0;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
    width: 30px;
    height: 22px;
    margin-right: 10px;
  }
}

.sections__downloadText {
  font-size: 16px;
  line-height: 1.1;
  white-space: nowrap;
  border-bottom: 1px solid $_colorTundora;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (min-width: 480px) {
  .sections__section {
    padding: 50px 0;
  }

  .sections__inner {
    &--one-column-image {
      margin: -50px auto;
    }
  }

  .sections__headline {
    font-size: 35px;
  }
}

@media (min-width: 550px) {
  .sections__downloads {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .sections__image {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .sections__inner {
    display: flex;

    &--two-column-text {
      .sections__headline {
        min-height: 1em;

        &:empty {
          display: block;
        }
      }
    }

    &--two-column-left {
      flex-direction: row-reverse;

      .sections__content {
        padding-left: 15px;
      }

      .sections__image {
        padding-right: 15px;
      }
    }

    &--two-column-right,
    &--two-column-contact {
      .sections__content {
        padding-right: 15px;
      }

      .sections__image {
        padding-left: 15px;
      }
    }

    &--two-column-text {
      .sections__content {
        &:first-child {
          padding-right: 15px;
        }

        &:last-child {
          padding-left: 15px;
        }

        & + .sections__content {
          margin-top: 0;
        }
      }
    }

    &--two-column-left,
    &--two-column-right,
    &--two-column-text,
    &--two-column-contact {
      .sections__content {
        width: 50%;
      }

      .sections__image {
        width: 50%;
      }
    }
  }
}

@media (min-width: $_wLarge) {
  .sections__downloads {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .sections__download {
    min-width: 50%;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .sections {
    .customContact__form {
      width: auto !important;
    }
  }
}
