.searchResultItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 25px;

  & + & {
    border-top: 1px solid $_colorGallery;
  }
}

.searchResultItem__image {
  width: 100%;
  max-width: 140px;
  min-height: 1px;
  flex-shrink: 0;
  margin-right: 30px;

  img {
    max-width: 110px;
  }
}

.searchResultItem__headline {
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 20px;
  color: $_colorTundora;
}

.searchResultItem__categories {
  margin-bottom: 5px;

  svg {
    margin: 0 5px;
    width: 8px;
    height: 8px;
  }

  path {
    fill: $_colorSilver;
  }
}

.searchResultItem__category {
  display: inline-block;
  font-size: 10px;
  color: $_colorTundora;
  white-space: nowrap;
}

.searchResultItem__text {
  max-width: 910px;
  color: $_colorTundora;
}

.searchResultItem__link {
  margin-top: 10px;
  font-weight: 600;
}

.searchResultItem__empty {
  font-size: 24px;
  font-weight: 600;
  color: $_colorTundora;
}

@media (min-width: 480px) {
  .searchResultItem {
    flex-direction: row;
  }
}
