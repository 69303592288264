$baseClass: 'ExitDialog';

.#{$baseClass} {
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);

    opacity: 0;
    pointer-events: none;

    &--animate {
        transition: opacity $_tShort ease-in-out $_tXShort;
    }

    &--visible {
        opacity: 1;
        pointer-events: all;

        transition: opacity $_tShort ease-in-out;

        .#{$baseClass}__center {
            opacity: 1;
            transform: translate(0, 0);

            transition: opacity $_tXShort ease-in-out, transform $_tShort ease-in-out;
        }
    }
}

.#{$baseClass}__inner {
    max-width: 480px;
    margin: 0 auto;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.#{$baseClass}__center {
    margin-top: 50px;
    width: 100%;
    background: $_colorWhite;

    transition: opacity $_tXShort ease-in-out, transform $_tShort ease-in-out;
    transform: translate(0, -50px);
    opacity: 0;
}

.#{$baseClass}__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
    font-weight: bold;
    border-bottom: 1px solid #DDDDDD;
}

.#{$baseClass}__close {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transition: background $_tXShort ease-in-out;

    svg path {
        fill: $_colorTundora;

        transition: fill $_tXShort ease-in-out;
    }

    &:hover {
        background: lighten($_colorShiraz, 5);

        svg path {
            fill: $_colorWhite;
        }
    }
}

.#{$baseClass}__text {
    padding: 25px 20px;
    font-size: 14px;
}

.#{$baseClass}__buttons {
    padding: 15px;
    border-top: 1px solid #DDDDDD;
    display: flex;
    flex-wrap: wrap;
}

.#{$baseClass}__button {
    flex-grow: 1;
}


@media (max-width: 479px) {
    padding: 15px;
}

@media (min-width: 480px) {
    .#{$baseClass}__headline {
        font-size: 30px;
    }
}

@media (min-width: $_wLarge) {
    .#{$baseClass}__headline {
        font-size: 40px;
    }
}
