.adhoc {
    display: block;
    position: relative;
    background: $_colorShiraz;

    transition: background $_tShort ease-in-out;

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        transform: translate(0, -100%);
        background: $_colorShiraz;
    }

    &:hover {
        background: lighten($_colorShiraz, 5);
    }
}

.adhoc__inner {
    position: relative;
    @include pageWidth;
}

.adhoc__close {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    cursor: pointer;

    svg {
        display: block;
        width: 15px;
        height: 15px;
    }

    path {
        fill: $_colorWhite;
    }
}

.adhoc__text {
    padding: 14px 45px 14px 0;
    color: $_colorHummingBird;
}
