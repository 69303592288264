$baseClass: "privacyOverlay";

.#{$baseClass} {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  padding: 20px;
  backdrop-filter: blur(5px);

  &:hover {
    opacity: 1;
  }

  &--dark {
    background: rgba(0, 0, 0, 0.75);
    color: $_colorWhite;
  }

  &--semiDark {
    background: rgba(0, 0, 0, 0.3);
    color: $_colorWhite;
  }

  &--bright {
    background: rgba(255, 255, 255, 0.75);
  }

  &--static {
    opacity: 1;
  }
}

.#{$baseClass}__inner {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.#{$baseClass}__headline {
  font-size: 24px;
  font-weight: 700;
}

.#{$baseClass}__text {
  margin-top: 14px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.#{$baseClass}__button {
  white-space: nowrap;
}
