.hero {
    position: relative;
    min-height: 400px;
    padding-bottom: 38%;
    overflow: hidden;

    background-color: $_colorAlto;
    background-position: top center;
    background-size: cover;

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background: $_colorWhite;
        transform: translate(0, -100%);
    }
}

.hero__inner {
    @include pageWidth(0);

    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    height: 100%;
}

.hero__content {
    background: rgba(255,255,255,0.7);
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.15);
    transition: background $_tMedium ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    max-width: 530px;
    padding: 20px 17px;
    color: darken($_colorChathamsBlueDark, 2);
    text-align: center;
}

.hero__small {
    display: block;

    font-weight: 600;
    font-size: 24px;
    line-height: 1;
}

.hero__large {
    display: block;

    font-weight: 700;
    font-size: 30px;
}

.hero__text {
    margin-top: 10px;
}

@media (min-width: 350px) {
    .hero__large {
        font-size: 34px;
    }
}

@media (min-width: 480px) {
    .hero__content {
        top: 50%;
        transform: translate(0, calc(-50% - 22px));
        -ms-transform: translate(0, -50%);
        padding: 20px;
        text-align: left;
    }

    .hero__small {
        font-size: 30px;
    }

    .hero__large {
        position: relative;
        left: -2px;
        font-size: 50px;
        line-height: 1.1;
    }
}

@media (min-width: 1250px) {
    .hero__content {
        background: transparent;
        box-shadow: none;
    }
}
