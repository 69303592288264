.productSubnavigation {
    background: $_colorMidGray;
}

.productSubnavigation__inner {
    @include pageWidth(0);
    padding: 0;
    display: flex;
    align-items: center;
    background: $_colorTundora;

    overflow-x: auto;
    @include removeScrollbar;
    -webkit-overflow-scrolling: touch;
}

.productSubnavigation__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 10px;
    flex-grow: 1;
    min-width: 100px;
    line-height: 1.1;

    text-align: center;
    font-size: 12px;
    color: $_colorWhite;

    transition: background $_tShort ease-in-out;

    & + &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        height: 50px;
        border-left: 1px solid $_colorMidGray;
    }

    &:hover {
        background: $_colorMidGray;
    }
}

.productSubnavigation__linkInner {}

@media (min-width: 480px) {
    .productSubnavigation__link {
        padding: 0 20px;
        font-size: 14px;
    }
}
