.notFound {
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}

.notFound__inner {
    width: 100%;
    max-width: 460px;
}

.notFound__headline {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}

.notFound__text {
    margin-top: 40px;

    font-size: 16px;
    text-align: center;

    a {
        color: $_colorShiraz;
        font-weight: 600;
    }
}

.notFound__form {
    margin-top: 25px;
    display: flex;
}

@media (max-width: 479px) {
    .notFound {
        br {
            display: none;
        }
    }
}

@media (min-width: 480px) {
    .notFound {
        padding: 100px;
    }
}
