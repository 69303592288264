.searchHero {
    background: $_colorAlabaster;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.2);
}

.searchHero__inner {
    @include pageWidth;
    padding-top: 25px;
    padding-bottom: 25px;
}

.searchHero__headline {
    font-weight: 600;
    font-size: 24px;
    color: $_colorTundora;
}

@media (min-width: 480px) {
    .searchHero__headline {
        font-size: 36px;
    }
}
