$baseClass: 'breadcrumbs';

.#{$baseClass} {
    width: 100%;

    &--padding {
        background: $_colorAlabaster;

        .#{$baseClass}__inner {
            @include pageWidth;

            max-width: 1110px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

.#{$baseClass}__inner {
    svg {
        display: inline-block;
        margin: 0 5px;
        width: 8px;
        height: 8px;
    }

    path {
        fill: $_colorSilver;
    }
}

.#{$baseClass}__crumb {
    display: inline-block;
    font-size: 10px;
    color: $_colorTundora;
    white-space: nowrap;
}

.#{$baseClass}__crumb--active {
    font-weight: 600;
}

@media (min-width: 480px) {
    .#{$baseClass} {
        &--padding {
            .#{$baseClass}__inner {
                padding-top: 25px;
                padding-bottom: 25px;
            }
        }
    }

    .#{$baseClass}__crumb {
        font-size: 12px;
    }
}
