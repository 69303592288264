$baseClass: 'magazineCategoryName';

.#{$baseClass} {
  margin-bottom: 25px;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  color: inherit;
  letter-spacing: 0;
  // text-transform: uppercase;
  transition: color 0.3s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 1px;
    width: 20px;
    background: currentColor;
  }
}
