$_colorWhite: #ffffff;
$_colorAlabaster: #f8f8f8;
$_colorGallery: #f0f0f0;
$_colorAlto: #d8d8d8;
$_colorSilver: #cccccc;
$_colorNobel: #b8b6b6;
$_colorDustyGray: #979797;
$_colorMidGray: #626263;
$_colorTundora: #4a4a4a;
$_colorMineShaft: #333333;
$_colorBlack: #000000;

$_colorHummingBird: #e9f7fc;
$_colorChathamsBlue: #136786;
$_colorChathamsBlueDark: #0f516a;

$_colorMountainMeadow: #17a057;

$_colorFlirt: #aa003c;
$_colorShiraz: #b50f48;
$_colorShirazDark: #a10b3f;

$_colorSalomie: #ffde92;
$_colorBrightSun: #ffc746;

$_colorLayer: rgba(0, 0, 0, 0.7);

$_rowWidth: 1300px;

$_tXShort: 0.1s;
$_tShort: 0.3s;
$_tMedium: 0.6s;
$_tLong: 1s;

$_wLarge: 769px;

// magazine

$_colorShipGray: #38313a;
$_colorGravel: #4d464f;
$_colorAmaethystSmoke: #aea4b1;
