.productInformation {
    background: $_colorAlabaster;

    overflow: hidden;
}

.productInformation__inner {
    @include pageWidth;
    overflow: hidden;
    max-width: 1110px;
    padding-top: 30px;
    padding-bottom: 40px;
}

.productInformation__headline {
    margin-bottom: 20px;

    font-weight: 600;
    font-size: 24px;
    color: $_colorTundora;
    text-align: center;
}

.productInformation__text {
    margin-bottom: 15px;

    color: $_colorTundora;
    @include wysiwyg;
}


.productInformation__sections {
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
}

.productInformation__section  {
    flex-grow: 1;
    width: 100%;
}

.productInformation__sectionInner {
    padding-top: 10px;
    margin: 10px 0 15px;
    border-top: 3px solid $_colorAlto;
}

.productInformation__section--red {
    .productInformation__sectionInner {
        background: transparentize($_colorFlirt, 0.8);
        border-color: $_colorShiraz;
    }

    .productInformation__sectionHeadline {
        color: $_colorShiraz;
    }
}

.productInformation__sectionHeadline {
    margin-bottom: 20px;

    font-weight: 600;
    font-size: 30px;
    color: $_colorTundora;
}

.productInformation__sectionText {
    color: $_colorTundora;
    @include wysiwyg;

    ul {
        padding-left: 1.2em;
        overflow: hidden;
    }

    li{
        text-overflow: ellipsis;
    }
}

.productInformation__legal {
    margin-top: 35px;

    border: 2px solid $_colorAlto;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px 15px;

    color: $_colorTundora;
    font-size: 12px;
    @include wysiwyg;
}

.productInformation__sectionDownloads {
    margin-top: 15px;
}

.productInformation__sectionDownload {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    svg {
        flex-shrink: 0;
        width: 30px;
        height: 22px;
        margin-right: 10px;
    }
}

.productInformation__sectionDownloadText {
    font-size: 16px;
    line-height: 1.1;
    color: $_colorTundora;
    white-space: nowrap;
    border-bottom: 1px solid $_colorTundora;
}




@media (min-width: 480px) {
    .productInformation__headline {
        margin-bottom: 20px;

        font-size: 30px;
        text-align: left;
    }

    .productInformation__sectionHeadline {
        margin-bottom: 30px;

        font-size: 35px;
        text-align: left;
    }

    .productInformation__text,
    .productInformation__sectionText {
        margin-bottom: 20px;

        font-size: 18px;
    }

    .productInformation__legal {
        font-size: 14px;
    }
}

@media (min-width: $_wLarge) {

    .productInformation__sections {
        margin-left: -10px;
        width: calc(100% + 20px);
    }

    .productInformation__section {
        padding: 0 10px;
    }

    .productInformation__section--half {
        width: 50%;
    }

    .productInformation__section--full {
        width: 100%;
    }

    .productHeader__downloads {
        display: flex;
    }
}

