// ===================
//  STYLE DEFINITIONS
// ===================

.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;

  opacity: 0;
  transform: translate(0, -50px);
  transition: opacity $_tShort ease-in-out, transform $_tShort ease-in-out;

  &--dark {
    .overlay__close {
      path {
        fill: $_colorTundora;
      }
    }
  }

  &::before {
    position: absolute;
    z-index: -1;
    content: "";
    width: 100%;
    height: 150vh;
    background: $_colorLayer;
    top: 0;
  }
}

.overlay__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 280px;
  max-height: calc(100vh - 40px);
  max-width: calc(100vw - 40px);

  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .productCategories__inner {
    padding: 0;
  }

  .productCategories__items {
    margin: 0;
  }

  .newsletter {
    width: calc(100vw - 40px) !important;
    max-width: 700px;
  }

  .form__headline {
    padding-right: 30px;
  }
}

.overlay__close {
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 5;
  top: 5px;
  right: 3px;
  width: 55px;
  height: 55px;
  padding: 0;

  appearance: none;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;

  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  path {
    fill: $_colorWhite;
  }
}

// ===================
//  STATE DEFINITIONS
// ===================

.overlay--visible {
  display: block;
}

.overlay--fade-in {
  opacity: 1;
  transform: translate(0, 0);
}
