.newsletterForm__privacy {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    text-align: right;
}

.newsletterForm__privacyCheckbox {
    padding-bottom: 6px;
}

.newsletterForm__privacyText {
    padding: 6px 0 16px 5px;
    font-size: 12px;
}

.newsletterForm__privacyLink {
    color: $_colorShiraz;
    text-decoration: underline;
    white-space: nowrap;
}
