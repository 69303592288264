$baseClass: 'header';

.#{$baseClass} {
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

  transform: translate3d(0, 0, 0);
  transition: transform $_tShort ease-in-out;
}

@media (min-width: 480px) {
  body.scrolled {
    .#{$baseClass} {
      transform: translate3d(0, -50px, 0);
    }
  }
}

@media print {
  .#{$baseClass} {
    display: none;
  }
}
