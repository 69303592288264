.seoText__inner {
    @include pageWidth;
    @include wysiwyg;

    color: $_colorTundora;
    padding: 30px 0;

    h1, h2, h3, h4, h5 {
        margin-top: 1em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 1340px) {
    .seoText__inner {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 480px) {
    .seoText__inner {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
