$baseClass: 'magazineArticleTile';

.#{$baseClass} {
    padding: 25px 10px;
    break-inside: avoid;

    &--highlight,
    &--hero,
    &--details {
        color: $_colorWhite;

        .#{$baseClass}__date {
            background: $_colorShiraz;
        }

        .#{$baseClass}__title {
            color: $_colorWhite;
        }

        .#{$baseClass}__categories,
        .#{$baseClass}__author {
            color: $_colorAmaethystSmoke;
        }
    }

    &--highlight,
    &--main {
        .#{$baseClass}__inner {
            &:hover {
                .#{$baseClass}__img,
                .#{$baseClass}__authorImg {
                    filter: saturate(1);
                }
            }
        }
    }

    &--main {
        .#{$baseClass}__inner {
            &:hover {
                .#{$baseClass}__categories,
                .#{$baseClass}__author {
                    color: $_colorTundora;
                }
            }
        }
    }

    &--highlight,
    &--hero,
    &--details {
        .#{$baseClass}__inner {
            &:hover {
                .#{$baseClass}__categories,
                .#{$baseClass}__author {
                    color: $_colorWhite;
                }
            }
        }
    }

    &--hero,
    &--details {
        padding: 0 !important;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        .#{$baseClass}__inner {
            padding: 25px 30px 30px 25px;
            background: $_colorShipGray;
        }

        .#{$baseClass}__date {
            top: 35px;
            left: auto;
            right: -33px;
        }
    }
}

.#{$baseClass}__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.#{$baseClass}__date {
    position: absolute;
    z-index: 1;
    top: -10px;
    left: -10px;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $_colorChathamsBlueDark;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: $_colorWhite;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
}

.#{$baseClass}__dateMonth {
    padding-top: 2px;
}

.#{$baseClass}__dateYear {
    font-weight: bold;
}

.#{$baseClass}__img {
    position: relative;
    margin-bottom: 35px;
    padding-bottom: 72%;
    background: $_colorAlabaster;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    filter: saturate(0);
    transition: filter 0.3s ease-in-out;
}

.#{$baseClass}__categories {
    color: $_colorNobel;
}

.#{$baseClass}__title {
    font-size: 24px;
    font-weight: bold;
    color: $_colorShipGray;
}

.#{$baseClass}__text {
    margin: 15px 0 0;
    font-size: 18px;
    line-height: 24px;

    @include wysiwyg;
}

.#{$baseClass}__authors {
    margin-top: 25px;
}

.#{$baseClass}__author {
    display: flex;
    align-items: center;
    color: $_colorNobel;
    transition: color 0.3s ease-in-out;

    & + & {
        margin-top: 8px;
    }
}

.#{$baseClass}__authorImgWrapper {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);

    &::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        display: block;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: $_colorAlabaster;
        border-radius: 50%;
        overflow: hidden;
    }
}

.#{$baseClass}__authorImg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    filter: saturate(0);
    transition: filter 0.3s ease-in-out;
}

.#{$baseClass}__authorName {
    margin-left: 12px;
    font-size: 15px;
}

.#{$baseClass}__button {
    display: inline-block;
    margin-top: 50px;
    min-width: 180px;
}

@media (min-width: 480px) {
    .#{$baseClass} {
        padding: 25px;

        &--hero,
        &--details {
            .#{$baseClass}__inner {
                padding: 35px 50px 50px 33px;
            }
        }
    }

    .#{$baseClass}__date {
        top: 25px;
        left: -33px;
    }
}

@media (min-width: 768px) {
    .#{$baseClass} {
        &--hideDesktop {
            display: none;
        }
    }
}

@media print {
    .#{$baseClass}__inner {
        padding: 0;
    }

    .#{$baseClass}__title {
        font-size: 18px;
    }

    .#{$baseClass}__text {
        font-size: 14px;
    }
}
