.listing {

    &:nth-child(2n) {
        background: $_colorGallery;

        .listing__item + .listing__item {
            border-top: 1px solid $_colorAlto;
        }
    }
}

.listing__inner {
    @include pageWidth();
    padding-top: 30px;
    padding-bottom: 30px;
}

.listing__headline {
    font-weight: 600;
    font-size: 24px;
    color: $_colorTundora;
    text-align: center;
}

.listing__text {
    max-width: 990px;

    p {
        line-height: 1.5;
    }
}

.listing__items {
    max-width: 1200px;
}

.listing__item {
    padding: 26px 0;

    & + & {
        border-top: 1px solid $_colorGallery;
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.listing__itemImage {
    width: 180px;
    flex-shrink: 0;

    img {
        margin: 0 auto;
        display: block;
        width: 100%;
        height: auto;
        box-shadow: 0 0 3px 0 rgba(0,0,0,0.2);
        background: $_colorWhite;
    }
}


.listing__itemContent {
    .button {
        margin-top: 15px;
    }
}

.listing__itemDate {
    font-size: 14px;
    color: $_colorTundora;
}

.listing__itemHeadline {
    padding: 5px 0 7px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    color: $_colorTundora;
}

.listing__itemLocation {
    font-size: 14px;
    color: $_colorTundora;
}

.listing__itemDescription {
    margin-top: 20px;

    font-size: 16px;
    line-height: 1.5;
    color: $_colorTundora;

    @include wysiwyg;
}

.listing__itemLink {
    margin-top: 20px;
}


@media (max-width: 479px) {
    .listing__itemImage {
        margin-bottom: 20px;

        &:empty {
            display: none;
        }
    }
}

@media (min-width: 480px) {
    .listing__inner {
        padding-top: 65px;
        padding-bottom: 50px;
    }

    .listing__item {
        display: flex;
    }

    .listing__itemContent {
        margin-left: 30px;
    }

    .listing__headline {
        font-size: 40px;
        text-align: left;
    }
}
