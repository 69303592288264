$baseClass: 'magazineCategory';

.#{$baseClass}__inner {
  padding-top: 50px;
  @include pageWidth;
}

.#{$baseClass}__title {
  font-weight: 36px;
  font-weight: 100;

  strong {
    font-weight: bold;
  }
}
